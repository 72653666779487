import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class AchivementCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader>  <b>Achivements</b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <ul>
                    <li>Outstanding contributor for Cyber Eye.</li>
                    <li>Best cyber capstone project in 2018-2019 from the Dean, College of Security and Intelligence.</li>
                    <li>Second place in machine learning challenge for the innovation hackathon.</li>
                    <li>Women academic achievement scholarship.</li>
                    <li>Black Hat security conference scholarship.</li>
                    <li>Acknowledged in paper for developing Python code to help the analysis of inflowing gas in the central parsec of M81.</li>
                </ul>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

export default AchivementCard;