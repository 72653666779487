import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader> <b> Projects</b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
              <h3>Hotel Business Center Security Research</h3>
                <ul>
                    <li>Accepted speaker for the Paraben Forensic Innovation Conference (PFIC) 2019.</li>
                    <li>Assessed physical and computer security of hotels around the United States to improve data protection programs.</li>
                </ul>
                <br />
                <h3>Aviation ISAC Capture the Flag</h3>
                <ul>
                    <li>Learned website exploitation using PHP methods.</li>
                    <li>Worked with a team to identify attack vectors for airplane Wi-Fi.</li>
                </ul>
                <br />
                <h3>Innovation Hackathon (N2Out)</h3>
                <ul>
                    <li>Built facial recognition tool with raspberry pi, neural compute stick, and Python.</li>
                </ul>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

export default ProjectCard;