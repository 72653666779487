import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class EducationCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader> <b> {this.props.headerText} </b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <h3>{this.props.school}, {this.props.location}</h3>
                <h4>{this.props.degree} </h4>
                <p>{this.props.details}</p>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

EducationCard.propTypes = {
    headerText: PropTypes.string,
    school: PropTypes.string,
    degree: PropTypes.string,
    location: PropTypes.string,
    details: PropTypes.string,
  };

export default EducationCard;
