import React from "react"
import { Link } from "gatsby"
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Collapse} from 'reactstrap';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import EducationCard from "../components/EducationCard"
import ExperienceCard from "../components/ExperienceCard";
import ProjectCard from "../components/ProjectCard";
import AffiliationCard from "../components/AffiliationCard";
import AchivementCard from "../components/AchivementCard";
import TechnicalSkillCard from "../components/TechnicalSkillCard"

const IndexPage = () => (
  <Layout>
    <SEO title="Resume" />
    <h4>Click on text below to reveal information</h4>
    <EducationCard headerText="Education" school="Embry-Riddle Aeronautical University (ERAU)" degree="Cyber Intelligence and Security, GPA: 3.64/4.0, Minors: Computer Science and Psychology" location="Prescott, AZ" details="Classes taken: Information and computer security, computer forensics, malware analysis, intelligence writing, database systems, operating systems, social psychology."/>
    <ExperienceCard/> 
    <ProjectCard/>
    <AffiliationCard/>
    <AchivementCard/>
    <TechnicalSkillCard/>
    <a href="https://www.linkedin.com/in/jessica-wilson-007/">Check out my Linkedin</a>
  </Layout>
)

export default IndexPage
