import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class AffiliationCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader> <b> Affiliations</b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <h3>High Technology Crime Investigation Association</h3>
                <ul>
                    <li>Attend monthly meetings to learn new computer forensic skills.</li>
                </ul>
                <br />
                <h3>Arizona Cyber Warfare Range</h3>
                <ul>
                    <li>Volunteer to learn and help others develop cyber security knowledge.</li>
                </ul>
                <br />
                <h3>InfraGard Member, AZ Chapter</h3>
                <ul>
                    <li>Research current events in cyber security.</li>
                </ul>
                <br />
                <h3>Women in Cyber Security (WiCyS)</h3>
                <ul>
                    <li>Establishing WiCyS chapter at ERAU.</li>
                </ul>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

export default AffiliationCard;