import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class TechnicalSkillCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader> <b> Technical Skills</b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <p>Python, SQL, C, C++, C#, Report Writing, Linux, Microsoft Windows, Java, HTML, CSS, and Splunk.</p>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

export default TechnicalSkillCard;