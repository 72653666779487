import React, { Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import PropTypes from "prop-types"

class ExperienceCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <div>
          <Card> 
          <a onClick={this.toggle}> <CardHeader>  <b>Experience</b></CardHeader></a>
            <Collapse isOpen={this.state.collapse}>
              <CardBody>
                <h3>Certified Ethical Hacker Class</h3>
                <p>EC-Council | May 2019-Present</p>
                <br />
                <h3>Computer Forensic Teachers Assistant</h3>
                <p>Embry-Riddle Aeronautical University | May 2019-Present</p>
                <ul>
                    <li>Creating class exercises and experimenting with forensic tools.</li>
                </ul>
                <br />
                <h3>CyberForce Competition</h3>
                <p>Department of Energy | Dec. 2018</p>
                <ul>
                    <li>Created virtual machine network in Microsoft Azure for users to safely preform their job activities in an Industrial Control System.</li>
                    <li>Monitored unauthorized red team intrusions to a mock water pump.</li>
                </ul>
                <br />
                <h3>Techstart Intern</h3>
                <p>Liberty Mutual Insurance | May 2018- Aug. 2018</p>
                <ul>
                    <li>Developed and documented support tool using C# with an agile software team.</li>
                    <li>Utilized dynamic testing procedures to ensure stability of application.</li>
                    <li>Information Technology help desk agent.</li>
                </ul>
                <br />
                <h3>System Administrator</h3>
                <p>Cyber Security Lab, Embry-Riddle Aeronautical University | Jan. 2017- Present</p>
                <ul>
                    <li>Create and setup virtual machines for static and dynamic malware analysis.</li>
                    <li>Setup an internal network for research.</li>
                    <li>Manage Active Directory for Windows 2012 server.</li>
                </ul>
                <br />
                <h3>Co-President and Head Report Writer</h3>
                <p>Cyber Eye Club, Embry-Riddle Aeronautical University| Sep. 2016- Oct. 2018</p>
                <ul>
                    <li>Generate reports from Open Source Intelligence (OSINT) for government and industry.</li>
                    <li>Coordinate meetings to teach penetration testing skills and technical writing to students.</li>
                </ul>
              </CardBody>
            </Collapse>
          </Card>
          <br />
      </div>
    );
  }
}

export default ExperienceCard;